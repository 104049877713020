





















import Vue from 'vue'
import { mapState } from 'vuex'
import TradePorfolio from './TradePorfolio.vue'
import TradeOrders from './TradeOrders.vue'
import TradeHistory from './TradeHistory.vue'
import PositionRecords from './PositionRecords.vue'
import Settlement from './Settlement.vue'
import BinanceMarginTnxHistory from './BinanceMarginTnxHistory.vue'

interface Tab {
  label: string
  name: string
  component: string
}

export default Vue.extend({
  components: {
    TradeHistory,
    TradePorfolio,
    TradeOrders,
    PositionRecords,
    Settlement,
    BinanceMarginTnxHistory
  },

  props: {
    maxHeightTableContent: {
      type: Number,
      required: true
    }
  },

  data(): {
    activeTabName: string
    currentPage: number
    refresh: boolean
  } {
    return {
      activeTabName: 'porfolio',
      currentPage: 1,
      refresh: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange']),

    TABS(): Tab[] {
      return [
        {
          label: 'Account Porfolio',
          name: 'porfolio',
          component: 'TradePorfolio',
          isShow: true
        },
        {
          label: 'Open Orders',
          name: 'orders',
          component: 'TradeOrders',
          isShow: !['tradias', 'tradiasTestnet'].includes(this.exchange)
        },
        {
          label: 'Trade History',
          name: 'history',
          component: 'TradeHistory',
          isShow: true
        },
        // {
        //   label: 'Positions',
        //   name: 'positions',
        //   component: 'PositionRecords',
        //   isShow: POSITION_EXCHANGE.includes(this.exchange)
        // },
        {
          label: 'Settlement',
          name: 'settlement',
          component: 'Settlement',
          isShow: ['tradias', 'tradiasTestnet'].includes(this.exchange)
        },
        {
          label: 'Margin History',
          name: 'binanceMarginTnxHistory',
          component: 'BinanceMarginTnxHistory',
          isShow: ['binanceMarginCross'].includes(this.exchange)
        }
      ].filter((item) => item.isShow)
    }
  },

  methods: {
    paginationChange(page = 10) {
      console.log(page)
    },

    clickRefreshButton() {
      this.refresh = true
    }
  }
})
