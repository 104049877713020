import axios from '@/plugins/axios'
import { BaseResponse } from '../base'
import { Trade, TradeHistoryReq } from './models'

const RESOURCES = Object.freeze({
  trade: '/exchange/transaction',
  tradeTradias: '/otc/trade-history'
})

export default {
  getTradeHistory(params: TradeHistoryReq) {
    return axios.get<BaseResponse<Partial<Trade>[] | null>>(RESOURCES.trade, {
      params
    })
  },

  getTradiasTradeHistory(params: TradeHistoryReq) {
    return axios.get<BaseResponse<Partial<Trade>[] | null>>(RESOURCES.tradeTradias, {
      params
    })
  }
}
