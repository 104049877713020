import axios from '@/plugins/axios'
import { BaseResponse } from '../base'
import { ExchangePosition } from './model'

const RESOURCES = Object.freeze({
  position: '/exchange/position/all-exchanges'
})

export default {
  getPosition(params: Partial<ExchangePosition> | null = null) {
    return axios.get<BaseResponse<any, ExchangePosition[] | null>>(RESOURCES.position, { params })
  }
}
