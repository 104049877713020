











import Vue from 'vue'
import { delay as _delay } from 'lodash'
import TradeOrders, { TableRecord } from '@/components/TradeOrders.vue'
import OrderService from '@/services/order'
import { mapMutations, mapState } from 'vuex'
import { ListOrderRes } from '@/services/order/models'

export default Vue.extend({
  components: {
    TradeOrders
  },

  props: {
    refresh: {
      type: Boolean,
      default: false
    },

    maxHeightTableContent: {
      type: Number,
      required: false
    }
  },

  data(): {
    tableData: unknown
    loading: boolean
  } {
    return {
      tableData: [],
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange']),
    ...mapState('flagTrigger', ['orderFlag'])
  },

  methods: {
    ...mapMutations('editOrderModal', ['SET_EDIT_ORDER_MODAL']),

    async getListActiveOrder() {
      this.tableData = []
      this.loading = true

      try {
        const req = {
          exchange: this.exchange
        }
        const { data: listActiveOrder } = await OrderService.getListActiveOrder(req)
        this.tableData = listActiveOrder?.custom?.data?.reduce(
          (accumulator: TableRecord[], currentValue: ListOrderRes) => {
            const record: TableRecord = {
              exchange: currentValue.exchange,
              symbol: currentValue.symbol,
              side: currentValue.side === 'B' ? 'BUY' : 'SELL',
              type: currentValue.order_type,
              size: currentValue.remaining_qty,
              price: currentValue.order_price,
              triggerPrice: '',
              options: '',
              transactTime: currentValue.timestamp,
              orderId: currentValue.order_id,
              status: currentValue.status,
              executed_qty: currentValue.executed_qty,
              base_ccy: currentValue.base_ccy,
              quote_ccy: currentValue.quote_ccy,
              created_by: currentValue.createdBy,
              text: currentValue.text,
              leg: currentValue.leg,
              positionType: currentValue.position_type
            }
            accumulator.push(record)

            return accumulator
          },
          []
        )
      } finally {
        this.$emit('update:refresh', false)
        this.loading = false
      }
    },

    openEditModal(val: TableRecord) {
      this.SET_EDIT_ORDER_MODAL(val)
    },

    localCancelAllUpdate() {
      this.tableData = (this.tableData as any).map((item: any) => ({
        ...item,
        status: 'Cancelled'
      }))
    }
  },

  created() {
    this.getListActiveOrder()
  },

  watch: {
    refresh(val) {
      if (val) {
        this.getListActiveOrder()
      }
    },

    exchange() {
      this.getListActiveOrder()
    },

    orderFlag() {
      this.loading = true

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _delay(this.getListActiveOrder, 1200)
    }
  }
})
