export enum SIDE_ORDER {
  SELL = 'SELL',
  BUY = 'BUY',
  B = 'B',
  S = 'S'
}

enum ORDER_TYPE {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT'
}

type ORDER_STATUS = 'NEW'

export interface OrderReq {
  exchange?: string
  price: number
  amount?: number
  symbol?: string
  positionType?: string
  leg?: string
  text?: string
  frontend: string
}

export interface CancelOrderReq {
  exchange: string
  id?: string
}

interface Meta {
  id: string
  isActive: boolean
  timestamp: number
}

interface Descr {
  leverage: string
  order: string
  ordertype: string
  pair: string
  price: string
  price2: string
  type: string
}

export interface Order {
  clientOrderId: string
  cummulativeQuoteQty: string
  executedQty: string
  fills: unknown[]
  meta: Meta
  orderId: number
  orderListId: number
  origQty: string
  price: string
  side: SIDE_ORDER
  status: ORDER_STATUS
  symbol: string
  timeInForce: string
  transactTime: number
  type: ORDER_TYPE
}

export interface ListOrderReq {
  exchange: string
}

export interface ListOrderRes {
  exchange: string
  orderId: number
  clientOrderId: string
  symbol: string
  transactTime: number
  price: string
  origQty: string
  executedQty: string
  status: 'NEW' | 'OLD'
  side: SIDE_ORDER
  fills: any[]
  descr: Descr
  order_type: ORDER_TYPE
  remaining_qty: string
  base_ccy: string
  order_price: string
  order_id: string
  timestamp: string
  qty_base_ccy_init: string | number
  qty_quote_ccy_init: string | number
  qty_base_ccy_remaining: string | number
  qty_quote_ccy_remaining: string | number
  qty_base_ccy_executed: string | number
  qty_quote_ccy_executed: string | number
  leg: string
  product_type: string
  quote_ccy: string
  position_type: string
  executed_qty: number
  createdBy: string
  text: string
}
