












































import Vue from 'vue'
import { mapState } from 'vuex'
import TradeService from '@/services/trade'
import { TradeHistoryReq, Trade, ListTradeHistoryRes } from '@/services/trade/models'
import filters from '@/filters'
import { SIDE_ORDER } from '@/services/order/models'
import moment from 'moment'

export interface TableRecord {
  symbol?: string
  side?: 'BUY' | 'SELL'
  orderType?: 'MARKET' | 'LIMIT'
  size?: number
  price?: number
  totalAmount?: number
  transactTime: string
}

export default Vue.extend({
  props: {
    maxHeightTableContent: {
      type: Number,
      required: true
    },

    refresh: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    datas: TableRecord[]
    loading: boolean
  } {
    return {
      datas: [],
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair']),

    ...mapState('flagTrigger', ['orderFlag']),

    tradiasExchange(): boolean {
      return ['tradiasTestnet', 'tradias'].includes(this.exchange)
    },

    paramsRequestTradeHistory(): TradeHistoryReq {
      return {
        exchange: this.exchange,
        symbol: this.pair?.symbol.toLowerCase()
      }
    },

    SIDES() {
      return SIDE_ORDER
    }
  },

  methods: {
    async getTradiasTradeHistory() {
      const { data: tradeHistoryResData } = await TradeService.getTradiasTradeHistory({
        exchange: this.exchange
      })

      this.datas =
        tradeHistoryResData.data
          ?.map((item: Partial<Trade>) => ({
            symbol: item.instrument,
            side: item.side,
            orderType: item.order_type,
            size: item.amount,
            price: item.confirmed_price,
            totalAmount: item.total_amount,
            transactTime: filters.moment_llll(String(item.executed_at))
          }))
          .sort((a, b) => new Date(b.transactTime).getTime() - new Date(a.transactTime).getTime()) || []
    },

    async getTradeHistory() {
      const { data: tradeHistoryResData } = await TradeService.getTradeHistory(this.paramsRequestTradeHistory)

      this.datas =
        tradeHistoryResData?.custom?.data
          .reduce((accumulator: TableRecord[], currentValue: ListTradeHistoryRes) => {
            const tradeRecords = {
              symbol: currentValue.symbol,
              side: currentValue.side,
              size: currentValue.exec_quantity,
              price: currentValue.exec_price,
              transactTime: filters.moment_llll(currentValue.transaction_time)
            }
            return accumulator.concat(tradeRecords as TableRecord)
          }, [])
          .sort(
            (a: TableRecord, b: TableRecord) => new Date(b.transactTime).getTime() - new Date(a.transactTime).getTime()
          ) || []
    },

    async getExchangeTradeHistory() {
      this.loading = true

      try {
        this.tradiasExchange ? await this.getTradiasTradeHistory() : await this.getTradeHistory()
      } finally {
        this.$emit('update:refresh', false)
        this.loading = false
      }
    }
  },

  watch: {
    refresh(val) {
      if (val) {
        this.getExchangeTradeHistory()
      }
    },

    orderFlag() {
      this.getExchangeTradeHistory()
    },

    paramsRequestTradeHistory: {
      handler(val: TradeHistoryReq) {
        if (!val.exchange || !val.symbol) return
        this.datas = []
        this.getExchangeTradeHistory()
      },
      deep: true,
      immediate: true
    }
  }
})
