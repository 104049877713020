

















































































































import Vue from 'vue'
import PositionService from '@/services/position'
import { ExchangePosition } from '@/services/position/model'
import { mapState } from 'vuex'
import { SIDE_ORDER } from '@/services/order/models'

export default Vue.extend({
  props: {
    maxHeightTableContent: {
      type: Number,
      required: true
    },

    refresh: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    tableData: ExchangePosition[]
    loading: boolean
  } {
    return {
      tableData: [],
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange']),

    SIDES() {
      return SIDE_ORDER
    }
  },

  watch: {
    exchange() {
      this.getPosition()
    },

    refresh(val) {
      if (val) {
        this.getPosition()
      }
    }
  },

  methods: {
    async getPosition() {
      this.loading = true

      try {
        const res = await PositionService.getPosition({ exchange: this.exchange })
        this.tableData = res.data.custom?.data || []
      } finally {
        this.loading = false
        this.$emit('update:refresh', false)
      }
    }
  },

  created() {
    this.getPosition()
  }
})
