










































































































































import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import OrderService from '@/services/order'
import { SIDE_ORDER } from '@/services/order/models'
import { MESSAGE_STATUS } from '@/store/modules/message/types'

export interface TableRecord {
  symbol: string
  side: string
  size: string
  price: string
  type: string
  options: string
  triggerPrice: string
  transactTime: string
  orderId: string
  status: string
  exchange?: string
  base_ccy: string
  quote_ccy: string
  executed_qty: number
  created_by: string
  text: string
  leg: string
  positionType: string
}

export default Vue.extend({
  props: {
    maxHeightTableContent: {
      type: Number,
      required: false
    },

    refresh: {
      type: Boolean,
      default: false
    },

    tableData: {
      type: Array as () => TableRecord[],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    exchange: {
      type: String,
      required: true
    }
  },

  data(): {
    confirmModalOpen: boolean
    cancelOrderId: string
  } {
    return {
      confirmModalOpen: false,
      cancelOrderId: ''
    }
  },

  computed: {
    ...mapState('flagTrigger', ['orderFlag']),

    SIDES() {
      return SIDE_ORDER
    },

    countOfNewOrder(): number {
      return this.tableData.filter((item) => item.status.toUpperCase() === 'NEW').length
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    ...mapMutations('flagTrigger', ['TOGGLE_ORDER_TRIGGER']),

    showConfirmCancelOrder(orderId: string) {
      this.confirmModalOpen = true
      this.cancelOrderId = orderId
    },

    closeConfirmCancelOrder() {
      this.confirmModalOpen = false
      this.cancelOrderId = ''
    },

    confirmCancelOrder() {
      if (!this.cancelOrderId) return

      if (this.cancelOrderId === 'all') {
        this.cancelAll()
      } else {
        this.cancelOneOrder()
      }
    },

    async cancelAll() {
      if (this.countOfNewOrder === 0) return

      this.$emit('update:loading', true)

      try {
        const dataReq = {
          exchange: this.exchange
        }

        // call cancel order
        await OrderService.cancelAllOrder(dataReq)

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: `${this.countOfNewOrder} order(s) canceled`
        })

        // trigger reload order
        this.TOGGLE_ORDER_TRIGGER()
      } finally {
        this.$emit('update:loading', false)
        this.closeConfirmCancelOrder()
      }
    },

    async cancelOneOrder() {
      this.$emit('update:loading', true)

      try {
        const dataReq = {
          exchange: this.exchange,
          id: this.cancelOrderId
        }

        // call cancel order
        await OrderService.cancelOrderExchange(dataReq)

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Cancel order success!'
        })

        // set order status to canceled
        const index = this.tableData.findIndex((item: TableRecord) => item.orderId === this.cancelOrderId)
        this.tableData[index].status = 'Canceled'
      } finally {
        this.$emit('update:loading', false)
        this.closeConfirmCancelOrder()
      }
    },

    async editOneOrder(record: TableRecord) {
      console.log(record)
      this.$emit('on-open-edit-modal', record)
    },

    displayedText(text: string): string {
      return text.length > 6 ? `${text.slice(0, 5)}...` : text
    }
  }
})
