





















































































import Vue from 'vue'
import { mapState } from 'vuex'
import TerminalCustomSelect from '@/views/SmartTrading/TradesTerminal/components/TerminalFormFilter/TerminalCustomSelect.vue'
import { SelectOption } from '@/models/form'
import { MarginAsset } from '@/services/crossMargin/models'
import CrossMarginService from '@/services/crossMargin'
import { cloneDeep as _cloneDeep } from 'lodash'

const ALL_OPTION = {
  label: 'All',
  value: ''
}

export default Vue.extend({
  props: {
    maxHeightTableContent: {
      type: Number,
      required: true
    },

    refresh: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    activeName: 'BORROW' | 'REPAY' | 'TRANSFER'
    marginAssetSelected?: SelectOption
    marginAssets: MarginAsset[]
    isLoading: boolean
    tableData: any
    disabled: boolean
  } {
    return {
      activeName: 'BORROW',
      marginAssetSelected: ALL_OPTION,
      marginAssets: [],
      isLoading: false,
      tableData: [],
      disabled: false
    }
  },

  components: {
    TerminalCustomSelect
  },

  computed: {
    ...mapState('exchange', ['exchange']),

    marginAssetOptions(): SelectOption[] {
      return [ALL_OPTION].concat(
        this.marginAssets.map((item) => ({
          label: item.assetFullName,
          value: item.assetName,
          img: `/images/${item.assetName.toUpperCase()}.jpg`
        }))
      )
    },

    tnxHistoryFiltered(): any[] {
      const tnxHistory = _cloneDeep(this.tableData)
      const marginAssetSelected = this.marginAssetSelected?.value
      if (marginAssetSelected !== '') {
        return tnxHistory.filter((item: any) => item.asset === marginAssetSelected)
      }

      return tnxHistory
    }
  },

  methods: {
    async getMarginAssets() {
      if (!this.exchange) return
      this.isLoading = true

      try {
        const { data: assets } = await CrossMarginService.getMarginAssets({ exchange: this.exchange })
        this.marginAssets = assets.data
        this.getTransactionHistory()
      } finally {
        this.isLoading = false
      }
    },

    async getTransactionHistory() {
      this.isLoading = true
      try {
        if (this.activeName === 'BORROW') {
          const { data } = await CrossMarginService.borrowHistory({
            exchange: this.exchange
            // currency: (this.marginAssetSelected?.value || '') as string
          })
          this.tableData = data.data
          console.log(data.data)
        } else if (this.activeName === 'REPAY') {
          const { data } = await CrossMarginService.repayHistory({
            exchange: this.exchange
            // currency: (this.marginAssetSelected?.value || '') as string
          })
          this.tableData = data.data
        } else {
          const { data } = await CrossMarginService.transferHistory({ exchange: this.exchange })
          this.tableData = data.data
        }
      } finally {
        this.isLoading = false
        this.$emit('update:refresh', false)
      }
    }
  },

  created() {
    this.getMarginAssets()
  },

  watch: {
    refresh(val) {
      if (val) {
        this.getTransactionHistory()
      }
    },

    activeName(val) {
      this.disabled = val === 'TRANSFER'
    }
  }
})
