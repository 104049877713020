import axios from '@/plugins/axios'
import { BaseResponse } from '../base'
import { SettlementReq } from './models'

const RESOURCES = Object.freeze({
  settlement: '/otc/settlement'
})

export default {
  getSettlement(body: SettlementReq) {
    return axios.post<BaseResponse<any, any | null>>(RESOURCES.settlement, body)
  }
}
