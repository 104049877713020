var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}]},[_c('div',{class:_vm.$style.currencySelect},[_c('TerminalCustomSelect',{attrs:{"disabled":_vm.disabled,"options":_vm.marginAssetOptions,"allow-like":false},model:{value:(_vm.marginAssetSelected),callback:function ($$v) {_vm.marginAssetSelected=$$v},expression:"marginAssetSelected"}})],1),_c('ElTabs',{on:{"tab-click":function($event){return _vm.getTransactionHistory()}},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('ElTabPane',{attrs:{"label":"Borrow","name":"BORROW"}},[_c('div',{class:_vm.$style.table},[_c('ElTable',{attrs:{"data":_vm.tnxHistoryFiltered,"height":_vm.maxHeightTableContent}},[_c('ElTableColumn',{attrs:{"label":"Time","prop":"timestamp","min-width":"116"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$filters.moment_llll(row.timestamp))+" ")]}}])}),_c('ElTableColumn',{attrs:{"label":"Currency","prop":"asset"}}),_c('ElTableColumn',{attrs:{"label":"Principal","prop":"principal"}}),_c('ElTableColumn',{attrs:{"label":"Status","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.status === 'CONFIRMED' ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])})],1)],1)]),_c('ElTabPane',{attrs:{"label":"Repay","name":"REPAY"}},[_c('div',{class:_vm.$style.table},[_c('ElTable',{attrs:{"data":_vm.tnxHistoryFiltered,"height":_vm.maxHeightTableContent}},[_c('ElTableColumn',{attrs:{"label":"Time","prop":"timestamp","min-width":"116"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$filters.moment_llll(row.timestamp))+" ")]}}])}),_c('ElTableColumn',{attrs:{"label":"Currency","prop":"asset"}}),_c('ElTableColumn',{attrs:{"label":"Principal","prop":"principal"}}),_c('ElTableColumn',{attrs:{"label":"Interest","prop":"interest"}}),_c('ElTableColumn',{attrs:{"label":"Amount","prop":"amount"}}),_c('ElTableColumn',{attrs:{"label":"Status","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.status === 'CONFIRMED' ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])})],1)],1)]),_c('ElTabPane',{attrs:{"label":"Transfer","name":"TRANSFER"}},[_c('div',{class:_vm.$style.table},[_c('ElTable',{attrs:{"data":_vm.tnxHistoryFiltered,"height":_vm.maxHeightTableContent}},[_c('ElTableColumn',{attrs:{"label":"Time","prop":"timestamp","min-width":"116"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$filters.moment_llll(row.timestamp))+" ")]}}])}),_c('ElTableColumn',{attrs:{"label":"Currency","prop":"asset"}}),_c('ElTableColumn',{attrs:{"label":"Transfer Counter Party","prop":"transferCounterparty"}}),_c('ElTableColumn',{attrs:{"label":"Type","prop":"type"}}),_c('ElTableColumn',{attrs:{"label":"Amount","prop":"amount"}}),_c('ElTableColumn',{attrs:{"label":"Status","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.status === 'CONFIRMED' ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }