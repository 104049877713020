















import Vue from 'vue'
import ExchangeService from '@/services/exchange'
import { mapState } from 'vuex'
import { ExchangeWalletBalance } from '@/services/exchange/models'

export default Vue.extend({
  props: {
    maxHeightTableContent: {
      type: Number,
      required: true
    },

    refresh: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    tableData: ExchangeWalletBalance[]
    loading: boolean
  } {
    return {
      tableData: [],
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair']),

    ...mapState('flagTrigger', ['orderFlag']),

    tradiasExchange(): boolean {
      return ['tradiasTestnet', 'tradias'].includes(this.exchange)
    }
  },

  methods: {
    async getTradiasWalletBalance() {
      try {
        const { data: dataRes } = await ExchangeService.getTradiasWalletBalance({
          exchange: this.tradiasExchange ? 'tradiasTestnet' : this.exchange,
          index: '150'
        })

        this.tableData = dataRes.data?.balances || []
      } finally {
        this.loading = false
        this.$emit('update:refresh', false)
      }
    },

    async getWalletBalance() {
      try {
        const { data: dataRes } = await ExchangeService.getWalletBalance({
          exchange: this.exchange
          // index: '150'
        })

        this.tableData = dataRes.custom?.data || []
      } finally {
        this.loading = false
        this.$emit('update:refresh', false)
      }
    },

    getExchangeWalletPortfolio() {
      this.loading = true
      this.tableData = []

      if (this.exchange === '') return
      this.tradiasExchange ? this.getTradiasWalletBalance() : this.getWalletBalance()
    }
  },

  watch: {
    orderFlag() {
      this.getExchangeWalletPortfolio()
    },

    exchange: {
      handler() {
        this.getExchangeWalletPortfolio()
      },
      immediate: true
    },

    refresh(val) {
      if (val) {
        this.getExchangeWalletPortfolio()
      }
    }
  }
})
