































































import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { cloneDeep as _cloneDeep, uniqueId as _uniqueId } from 'lodash'
import { SelectOption } from '@/models/form'
import { ExchangeRefInstrument } from '@/services/exchange/models'
import TerminalCustomSelect from '@/views/SmartTrading/TradesTerminal/components/TerminalFormFilter/TerminalCustomSelect.vue'
import SettlementService from '@/services/settlement'
import moment from 'moment'
import { RequestItem, SettlementReq } from '@/services/settlement/models'

type SettlementRequestItem = {
  id: string
  currency: SelectOption | undefined
  fromAddress: string
  toAddress: string
  amount: number
}

const requestItem: SettlementRequestItem = {
  id: _uniqueId('request_'),
  currency: undefined,
  fromAddress: '',
  toAddress: '',
  amount: 0
}

export default Vue.extend({
  name: 'Settlement',

  props: {
    maxHeightTableContent: {
      type: Number,
      default: 400
    }
  },

  components: {
    TerminalCustomSelect
  },

  data(): {
    params: {
      dateRange: [Date, Date]
      requests: SettlementRequestItem[]
    }
  } {
    return {
      params: {
        dateRange: [new Date(), new Date()],
        requests: []
      }
    }
  },

  computed: {
    ...mapState('exchange', ['refInstrument', 'exchange']),

    currencyOptions(): SelectOption[] {
      return this.refInstrument
        .filter((ref: ExchangeRefInstrument) => ref.exchange === this.exchange)
        .reduce((acc: SelectOption[], currentVal: ExchangeRefInstrument) => {
          const baseCurrencyIndex = acc.findIndex((item: SelectOption) => item.value === currentVal.baseCurrency)
          if (baseCurrencyIndex < 0) {
            acc.push({
              value: currentVal.baseCurrency,
              label: currentVal.baseCurrency,
              img: `/images/${currentVal.baseCurrency.toUpperCase()}.jpg`
            })
          }

          const quoteCurrencyIndex = acc.findIndex((item: SelectOption) => item.value === currentVal.quoteCurrency)
          if (quoteCurrencyIndex < 0) {
            acc.push({
              value: currentVal.quoteCurrency,
              label: currentVal.quoteCurrency,
              img: `/images/${currentVal.quoteCurrency.toUpperCase()}.jpg`
            })
          }

          return acc
        }, [])
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    addRequestItem() {
      this.params.requests.push({
        ..._cloneDeep(requestItem),
        id: _uniqueId('request_'),
        currency: this.currencyOptions[0]
      })
    },

    removeRequestItem(id: string) {
      this.params.requests = this.params.requests.filter((request) => request.id !== id)
    },

    async submit() {
      // get params request
      if (!this.params.requests.length) {
        return this.SET_MESSAGE({
          type: 'error',
          message: 'Please add at least one request'
        })
      }

      const bodyRequests: SettlementReq = {
        exchange: 'tradiasTestnet',
        fromDate: moment(this.params.dateRange[0]).startOf('day').utc().format(),
        toDate: moment(this.params.dateRange[1]).endOf('day').utc().format(),
        requests: this.params.requests.map((request) => ({
          currency: request.currency?.value || '',
          fromAddress: request.fromAddress,
          toAddress: request.toAddress,
          amount: Number(request.amount)
        })) as RequestItem[]
      }

      try {
        await SettlementService.getSettlement(bodyRequests)
      } finally {
      }
    }
  }
})
