var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.table},[_c('ElTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.refresh || _vm.loading),expression:"refresh || loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.datas,"height":_vm.maxHeightTableContent,"stripe":""}},[_c('ElTableColumn',{attrs:{"prop":"symbol","label":"Pair"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:_vm.$style.tile},[_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(row.symbol))])])]}}])}),_c('ElTableColumn',{attrs:{"prop":"side","label":"Side"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.side === _vm.SIDES.BUY ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.side === _vm.SIDES.BUY ? 'BUY' : 'SELL')+" ")])]}}])}),_c('ElTableColumn',{attrs:{"prop":"orderType","label":"Order type"}}),_c('ElTableColumn',{attrs:{"prop":"size","label":"Size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.size ? row.size : 0))]}}])}),_c('ElTableColumn',{attrs:{"prop":"price","label":"Exec Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.price ? row.price : 0))]}}])}),_c('ElTableColumn',{attrs:{"prop":"totalAmount","label":"Total Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.totalAmount ? row.totalAmount : 0)+" ")]}}])}),_c('ElTableColumn',{attrs:{"prop":"transactTime","label":"Date","min-width":"116"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.transactTime))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }