import axios, { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '../base'
import { OrderReq, Order, ListOrderReq, ListOrderRes, CancelOrderReq } from './models'

const RESOURCES = Object.freeze({
  order: '/exchange/order',
  tradias: 'otc/order',
  allOrder: '/exchange/order/all'
})

export default {
  getAllOrders(params: ListOrderReq | undefined = undefined) {
    return axios.get<BaseResponse<any, ListOrderRes[]>>(`${RESOURCES.order}/all-exchanges`, { params })
  },

  getListActiveOrder(params: ListOrderReq) {
    // eslint-disable-next-line no-extra-boolean-cast
    const localParams = !!params.exchange ? { exchange: params.exchange } : {}
    return axios.get<BaseResponse<any, ListOrderRes[] | null>>('/exchange/order/active', { params: localParams })
  },

  triggerOrderExchange(data: OrderReq) {
    return axios.post<BaseResponse<Order>>(RESOURCES.order, data)
  },

  triggerOrderTradiasExchange(data: OrderReq) {
    return axios.post<BaseResponse<Order>>(RESOURCES.tradias, data)
  },

  cancelOrderExchange(data: CancelOrderReq) {
    const config: AxiosRequestConfig = {
      data: data
    }
    return axios.delete<BaseResponse<Order>>(RESOURCES.order, config)
  },

  cancelAllOrder(data: CancelOrderReq) {
    const config: AxiosRequestConfig = {
      data: data
    }
    return axios.delete<BaseResponse<Order>>(RESOURCES.allOrder, config)
  },

  editOrderExchange(data: Partial<OrderReq>) {
    return axios.put<BaseResponse<any>>(RESOURCES.order, data)
  }
}
