var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.table},[_c('ElTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.refresh || _vm.loading),expression:"refresh || loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":_vm.maxHeightTableContent,"stripe":""}},[_c('ElTableColumn',{attrs:{"prop":"symbol","label":"Pair"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:_vm.$style.tile},[_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(row.symbol))])])]}}])}),_c('ElTableColumn',{attrs:{"prop":"side","label":"Side"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTag',{attrs:{"type":row.side === _vm.SIDES.BUY ? 'primary' : 'danger',"effect":"light","size":"mini"}},[_vm._v(" "+_vm._s(row.side)+" ")])]}}])}),_c('ElTableColumn',{attrs:{"prop":"size","label":"Remaining Size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.size ? row.size : 0))]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Remaining "),_c('br'),_vm._v(" Size ")])])],2),_c('ElTableColumn',{attrs:{"prop":"size","label":"Filled Size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.executed_qty ? row.executed_qty : 0))]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Filled "),_c('br'),_vm._v(" Size ")])])],2),_c('ElTableColumn',{attrs:{"prop":"type","label":"Order Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.type))]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Order "),_c('br'),_vm._v(" Type ")])])],2),_c('ElTableColumn',{attrs:{"prop":"price","label":"Order Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.price ? row.price : 0))]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Executed "),_c('br'),_vm._v(" Price ")])])],2),_c('ElTableColumn',{attrs:{"prop":"created_by"}},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Created "),_c('br'),_vm._v(" By ")])])],2),_c('ElTableColumn',{attrs:{"label":"Text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElTooltip',{attrs:{"content":row.text,"placement":"top"}},[_c('p',[_vm._v(_vm._s(_vm.displayedText(row.text)))])])]}}])}),_c('ElTableColumn',{attrs:{"prop":"transactTime","label":"Creation Date","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.$filters.moment_llll(row.transactTime)))])]}}])},[_c('template',{slot:"header"},[_c('p',[_vm._v(" Creation "),_c('br'),_vm._v(" Date ")])])],2),_c('ElTableColumn',{attrs:{"label":"Cancel","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(['NEW', 'PARTIALLYFILLED'].includes(row.status.toUpperCase()))?_c('div',{class:_vm.$style.actionButtons},[_c('ElButton',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.editOneOrder(row)}}},[_vm._v("Amend")]),_c('ElButton',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.showConfirmCancelOrder(row.orderId)}}},[_vm._v("Cancel")])],1):_c('p',[_vm._v(_vm._s(row.status))])]}}])},[_c('template',{slot:"header"},[_c('ElButton',{class:_vm.$style.cancelAllBtn,attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.showConfirmCancelOrder('all')}}},[_vm._v(" Cancel All ")])],1)],2)],1),_c('ElDialog',{class:_vm.$style.dialogWrapper,attrs:{"modal-append-to-body":"","append-to-body":"","center":"","destroy-on-close":"","width":"400px","title":"Cancel Order","visible":_vm.confirmModalOpen},on:{"close":_vm.closeConfirmCancelOrder}},[_c('p',[_vm._v("Are you sure you want to cancel this order?")]),_c('div',{class:_vm.$style.btnGroup},[_c('ElButton',{attrs:{"type":"danger"},on:{"click":_vm.confirmCancelOrder}},[_vm._v("Yes")]),_c('ElButton',{attrs:{"type":"primary"},on:{"click":_vm.closeConfirmCancelOrder}},[_vm._v("No")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }